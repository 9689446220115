@import "_bootstrap-variables";
@import "_bootstrap";
@import "_bootstrap-helpers";
@import "_custom-variables";
@import "_google-fonts";


.btn-link {
    text-align: inherit;
}

.sticky-stacked-top {
    top: var(--topnav-height, 0);
}