// Typography
$font-family-sans-serif: "Roboto Condensed", sans-serif;


// Navs
$nav-tabs-border-width: 2px;


// Navbar
$navbar-padding-y: 0;


// Breadcrumbs
$breadcrumb-font-size: .87em;
$breadcrumb-margin-bottom: 0;
$breadcrumb-active-color: var(--bs-body-color);
